import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Upload from "./pages/Upload";
import SignIn from "./pages/SignIn";
import Queue from "./pages/Queue";
import Layout from "./components/Layout";
import { ChakraProvider } from "@chakra-ui/react";
import { ReactElement } from "react";
import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import "./global.css";

const QueryErrorHandler = ({ children }: { children: ReactElement }) => {
  const queryClient = new QueryClient({
    mutationCache: new MutationCache({
      onSettled: () => {
        queryClient.invalidateQueries();
      },
    }),

    defaultOptions: {
      queries: {
        staleTime: 3000,
        gcTime: Infinity,
        retry: 0,
        refetchOnWindowFocus: false,
      },
      mutations: {
        gcTime: 0,
        retry: 0,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

const App = () => {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <QueryErrorHandler>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<SignIn />} />
              <Route path="queue" element={<Queue />} />
              <Route path="upload" element={<Upload />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        </QueryErrorHandler>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
