import { useLocation, useNavigate } from "react-router-dom";
import service from "../../service/service";
import { useMutation } from "@tanstack/react-query";
import { DynamicFormInputs } from "../../pages/Upload";
import { ToastId, useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { AxiosError } from "axios";
import { removeAccessToken } from "../../utils/auth";
import { UXMessages } from "../../utils/constants";

export const useCreateQueueMutation = () => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const toast = useToast({ duration: 5000 });
  const toastIdRef = useRef<ToastId>();

  const { mutate: createQueueMutation, isPending } = useMutation({
    mutationFn: (payload: DynamicFormInputs[]) => service.createQueue(payload),

    onMutate: () => {
      toastIdRef.current = toast({
        status: "loading",
        title: "업로드 중",
        duration: null,
      });
    },

    onSuccess: (data) => {
      const { uploaded, ignored, tried } = data;
      const toastId = toastIdRef.current;

      if (toastId) {
        if (tried === 0) {
          toast.update(toastId, {
            status: "warning",
            title: "업로드 실패",
            description: `잘못된 형식의 엑셀 파일입니다. 예시 엑셀 파일을 참고해주세요.`,
          });
        } else if (ignored === 0) {
          toast.update(toastId, {
            status: "success",
            title: "업로드 성공",
            description: `${tried}건 중 ${uploaded}건 업로드 성공`,
          });
        } else {
          toast.update(toastId, {
            status: "warning",
            title: "업로드 성공",
            description: (
              <>
                {tried}건 중 {uploaded}건 업로드 성공
                <br />
                (실패 사유: 전화번호 중복 또는 한국 전화번호가 아님)
              </>
            ),
            duration: 1000 * 10,
          });
        }
      }

      if (pathname !== "/queue") navigate("/queue");
    },

    onError: (error) => {
      const toastId = toastIdRef.current;

      if (error instanceof AxiosError && !!toastId) {
        if (error.response?.data === "INVALID TOKEN") {
          removeAccessToken();
          navigate("/");
          toast.update(toastId, {
            status: "error",
            title: "인증 실패",
            description: UXMessages["토큰_만료"],
          });
        } else if (error.response?.data === "LOCK UNAVAILABLE") {
          toast.update(toastId, {
            status: "error",
            title: "업로드 실패",
            description: UXMessages["동시_시도"],
          });
        } else if (error.response?.data === "EDIT NOT ALLOWED") {
          toast.update(toastId, {
            status: "error",
            title: "업로드 실패",
            description: UXMessages["변경_불가능"],
          });
        } else if ((error.response?.status ?? 501) >= 500) {
          toast.update(toastId, {
            status: "error",
            title: "업로드 실패",
            description: UXMessages["범용적인_에러문구"](201),
          });
        }
      } else {
        toastId &&
          toast.update(toastId, {
            status: "error",
            title: "업로드 실패",
            description: UXMessages["범용적인_에러문구"](202),
          });
      }
    },
  });

  return { createQueueMutation, isPending };
};
