import * as XLSX from "xlsx";

export const tooltipModifiers = [
  {
    name: "offset",
    options: {
      offset: [0, 5], // 첫 번째 값은 수평 방향으로의 거리, 두 번째 값은 수직 방향으로의 거리를 의미합니다.
    },
  },
];

type Booking = {
  phone: string;
  name: string;
  bookingArrival: string;
  dayOfWeek: string;
  eventName: string;
  room: string;
  onsiteContact: string;
  startTime: string;
};

export const parseExcel = (file: File): Promise<Booking[]> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target) {
        reject(new Error("파일 리더 이벤트 타겟이 존재하지 않습니다."));
        return;
      }

      const arrayBuffer = e.target.result;
      if (arrayBuffer === null || !(arrayBuffer instanceof ArrayBuffer)) {
        reject(
          new Error("파일 내용을 ArrayBuffer로 읽어오는 데 실패했습니다.")
        );
        return;
      }

      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json: any[] = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        raw: true,
        blankrows: false,
        defval: "",
      });

      // 데이터 테이블이 시작되는 인덱스를 찾습니다.
      const startRowIndex = json.findIndex(
        (row) => row && row[0] && row[0].startsWith("Booking:")
      );
      if (startRowIndex === -1) {
        reject(new Error("유효한 데이터 행을 찾을 수 없습니다."));
        return;
      }

      const results: Booking[] = [];

      // 제목 행 다음부터 데이터를 추출합니다.
      json.slice(startRowIndex + 1).forEach((row, index) => {
        // 요일 데이터를 소문자로 변환합니다.
        const dayOfWeekLower = row[3] ? row[3].toLowerCase() : "";
        // 변환된 요일이 유효한지 확인합니다.
        if (
          !validDaysOfWeek.includes(dayOfWeekLower) &&
          dayOfWeekLower !== ""
        ) {
          reject(
            new Error(
              `행 ${index + startRowIndex + 3}: 잘못된 요일 값 '${row[3]}'`
            )
          );
          return;
        }

        // 필수 입력 컬럼 확인
        if (row[0].length === 0) {
          reject(
            new Error(
              `행 ${
                index + startRowIndex + 3
              }: Booking: Booking Post As는 필수 입력 항목입니다.`
            )
          );
          return;
        }
        // 필수 입력 컬럼 확인
        if (row[5].length === 0) {
          reject(
            new Error(
              `행 ${
                index + startRowIndex + 3
              }: Booking: Customer Phone는 필수 입력 항목입니다.`
            )
          );
        }
        // 첫 글자를 대문자로 변환합니다.
        const dayOfWeek =
          dayOfWeekLower.charAt(0).toUpperCase() + dayOfWeekLower.slice(1);

        // 날짜 값이 유효한지 확인하고, 유효하면 YYYY-MM-DD 형식으로 변환합니다.
        const bookingArrivalFormatted = convertDateToStandardFormat(row[0]);

        results.push({
          phone: row[5],
          name: row[4],
          bookingArrival: bookingArrivalFormatted,
          dayOfWeek: dayOfWeek,
          startTime: convertExcelTimeToStandard(row[2]),
          room: row[1],
          onsiteContact: row[6],
          eventName: "", // 이벤트 이름, 해당 데이터가 있다면 열 인덱스를 맞게 수정
        });
      });

      resolve(results);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });

const convertExcelTimeToStandard = (time: number | string): string => {
  if (typeof time === "number") {
    const hours = Math.floor(time * 24);
    const minutes = Math.floor((time * 24 * 60) % 60);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }
  return time.toString();
};

export const intervalCheckTime = 1000;

export const dayOfWeekMap = new Map([
  [0, "일"],
  [1, "월"],
  [2, "화"],
  [3, "수"],
  [4, "목"],
  [5, "금"],
  [6, "토"],
]);

export const DISABLED_TIME = {
  dayOfWeek: 2,
  startTime: 10,
  endTime: 11,
};

export const validDaysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const isValidDate = (dateString: string) => {
  const regex = /^\d{4}\.(\d{1,2})\.(\d{1,2})$/;

  if (!regex.test(dateString)) {
    return false;
  }

  const parts = dateString.split(".");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  // JavaScript의 Date 객체를 사용하여 날짜 유효성을 확인합니다.
  const date = new Date(year, month - 1, day);
  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
};

const convertDateToStandardFormat = (dateString: string): string => {
  if (!isValidDate(dateString)) {
    throw new Error(`잘못된 형식의 날짜입니다: ${dateString}`);
  }

  // 날짜 형식이 유효하다고 확인된 후에 형식을 변환합니다.
  const parts = dateString.split(".");
  const year = parts[0];
  // 월과 일을 두 자리 숫자로 맞춥니다.
  const month = parts[1].padStart(2, "0");
  const day = parts[2].padStart(2, "0");

  return `${year}-${month}-${day}`;
};
