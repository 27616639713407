import { create } from "zustand";
import { DISABLED_TIME } from "../../utils/utils";

type State = {
  isDisabledUpdate: boolean;
};

type Action = {
  checkTimeAndDay: VoidFunction;
};

const initialState: State = {
  isDisabledUpdate: false,
};

export const useDisabledUpdateStore = create<State & Action>((set) => ({
  ...initialState,

  checkTimeAndDay: () => {
    const now = new Date();
    const day = now.getDay();
    const hours = now.getHours();

    const isDisabled =
      day === DISABLED_TIME.dayOfWeek &&
      DISABLED_TIME.startTime <= hours &&
      hours < DISABLED_TIME.endTime;

    set({ isDisabledUpdate: isDisabled });
  },
}));
