import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";

type State = {
  formIds: string[];
  formCount: number;
  availableAdd: boolean;
};

type Action = {
  addForm: VoidFunction;
  subForm: (id: string) => void;
  toggleAvailableAdd: VoidFunction;
  reset: VoidFunction;
};

const initialState: State = {
  formCount: 1,
  formIds: [uuidv4()],
  availableAdd: false,
};

export const useQueueStore = create<State & Action>((set) => ({
  ...initialState,

  addForm: () => set((state) => ({ formIds: [...state.formIds, uuidv4()] })),
  subForm: (id) =>
    set((state) => ({
      formIds: state.formIds.filter((formId) => formId !== id),
    })),
  toggleAvailableAdd: () =>
    set((state) => ({ availableAdd: !state.availableAdd })),
  reset: () => set(initialState),
}));
