import { useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  VStack,
  Heading,
  Flex,
  Image,
} from "@chakra-ui/react";
import { useSignInMutation } from "../hooks/mutations/useSignInMutation";
import { WALKERHILL_LOGO } from "../utils/images";

interface FormInput {
  id: string;
  password: string;
}

const SignIn = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>();

  const { signInMutation, isPending } = useSignInMutation();

  const onSubmit = (data: FormInput) => {
    signInMutation(data);
  };

  return (
    <Flex justifyContent={"center"} h={"100%"} alignItems={"center"}>
      <VStack>
        <Image w={"800px"} h={"auto"} mb={"10px"} src={WALKERHILL_LOGO} />
        <Heading fontSize={"3xl"} color={"#333333"} pb={"40px"}>
          워커힐 웨딩 만족도 조사 설문 발송 백오피스
        </Heading>

        <VStack spacing={4} as="form" onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={!!errors.id}>
            <FormLabel htmlFor="id">ID</FormLabel>
            <Input
              w={"600px"}
              id="use_id"
              placeholder="아이디를 입력해주세요."
              {...register("id", { required: "아이디를 입력해주세요." })}
            />
            <FormErrorMessage>
              {errors.id && errors.id.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.password}>
            <FormLabel htmlFor="password">PASSWORD</FormLabel>
            <Input
              id="password"
              type="password"
              placeholder="패스워드를 입력해주세요."
              {...register("password", {
                required: "패스워드를 입력해주세요.",
              })}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            mt={"20px"}
            w={"150px"}
            bg={"#333333"}
            color={"white"}
            isLoading={isPending}
            type="submit"
            _hover={{ bg: "gray" }}
          >
            로그인
          </Button>
        </VStack>
      </VStack>
    </Flex>
  );
};

export default SignIn;
