import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Grid,
  Heading,
  IconButton,
  Input,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Tooltip,
  UnorderedList,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { RegisterOptions, useForm } from "react-hook-form";
import { useShallow } from "zustand/react/shallow";
import { useQueueStore } from "../hooks/store/useQueueStore";
import { useUnmount } from "react-use";
import { useNavigate } from "react-router-dom";
import {
  dayOfWeekMap,
  DISABLED_TIME,
  intervalCheckTime,
  tooltipModifiers,
  validDaysOfWeek,
} from "../utils/utils";
import { useCreateQueueMutation } from "../hooks/mutations/useCreateQueueMutation";
import { Button as ButtonAntd } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useEffect, useRef } from "react";
import { useDisabledUpdateStore } from "../hooks/store/useDisabledUpdateStore";
import { LogoutOutlined } from "@ant-design/icons";
import { removeAccessToken } from "../utils/auth";
import { UXMessages } from "../utils/constants";

export interface DynamicFormInputs {
  [key: string]: string | null;
}

const Upload = () => {
  const { isDisabledUpdate, checkTimeAndDay } = useDisabledUpdateStore(
    useShallow((state) => ({
      isDisabledUpdate: state.isDisabledUpdate,
      checkTimeAndDay: state.checkTimeAndDay,
    }))
  );

  const { createQueueMutation, isPending } = useCreateQueueMutation();

  const toast = useToast();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<DynamicFormInputs>({ mode: "onChange" });
  const navigate = useNavigate();
  const { formIds, reset } = useQueueStore(
    useShallow((state) => ({
      formIds: state.formIds,
      reset: state.reset,
    }))
  );

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const cancelRef = useRef(null);

  const onSubmit = (data: DynamicFormInputs) => {
    const parseObject = (data: DynamicFormInputs): DynamicFormInputs[] => {
      const parsed: Record<string, any> = {};

      Object.keys(data).forEach((key) => {
        const [property, id] = key.split("/");
        if (!parsed[id]) {
          parsed[id] = {};
        }

        // 날짜 형식을 정규표현식을 사용하여 변환합니다.
        if (property === "bookingArrival") {
          const dateMatch = data[key]?.match(/(\d{4})(\d{2})(\d{2})/);
          if (dateMatch) {
            parsed[id][
              property
            ] = `${dateMatch[1]}-${dateMatch[2]}-${dateMatch[3]}`;
          } else {
            // 형식이 맞지 않으면 원래 값을 그대로 사용
            parsed[id][property] = data[key];
          }
        } else {
          parsed[id][property] = data[key];
        }
      });

      return Object.values(parsed);
    };

    const parsedArray = parseObject(data);

    if (isDisabledUpdate) {
      toast({
        title: "업로드 실패",
        description: UXMessages["변경_불가능"],
        status: "warning",
      });
    } else {
      createQueueMutation(parsedArray);
    }
  };

  const fields: {
    label: string;
    placeholder?: string;
    type?: React.HTMLInputTypeAttribute;
    maxLength?: number;
    helperText?: string;
    options?:
      | RegisterOptions<DynamicFormInputs, `${string}/${string}`>
      | undefined;
  }[] = [
    {
      label: "name",
      placeholder: "고객명",
    },
    {
      label: "phone",
      placeholder: "전화번호 *",
      helperText: "01012345678 또는 821012345678 형식으로 입력해주세요.",
      maxLength: 12,
      options: {
        required: " ",
        pattern: {
          value: /^(8\d{11}|0\d{10})$/, // 8 또는 0으로 시작하는 숫자 패턴
          message: "8로 시작하면 12자리, 0으로 시작하면 11자리",
        },
      },
    },
    {
      label: "bookingArrival",
      placeholder: "예약날짜 *",
      maxLength: 8,
      helperText: "YYYYMMDD 형식으로 8자리 숫자만 입력해주세요.",
      options: {
        required: " ",
        pattern: {
          value: /^\d{8}$/, // 8자리 숫자만 허용
          message: "YYYYMMDD 형식으로 8자리 숫자만 입력해주세요.",
        },
      },
    },
    {
      label: "dayOfWeek",
      placeholder: "예약요일",
    },
    {
      label: "eventName",
      placeholder: "이벤트",
    },
    {
      label: "room",
      placeholder: "장소",
    },
    {
      label: "onsiteContact",
      placeholder: "관리자",
    },
  ];

  const allFields = watch();

  // 폼에 데이터가 하나라도 존재하는지 확인하는 함수입니다.
  const isFormDirty = () => {
    return Object.values(allFields).some(
      (value) => value !== null && value !== ""
    );
  };

  const handleBackClick = () => {
    if (isFormDirty()) {
      onOpenModal();
    } else {
      navigate("/queue");
    }
  };

  useEffect(() => {
    const intervalId = setInterval(checkTimeAndDay, intervalCheckTime);

    return () => clearInterval(intervalId);
  }, [checkTimeAndDay]);

  useUnmount(() => reset());

  return (
    <VStack spacing={5} pt={"20px"}>
      <Flex
        position={"relative"}
        w={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        mb={"10px"}
      >
        <ButtonAntd
          icon={<ArrowRightOutlined rotate={180} />}
          onClick={handleBackClick}
          style={{ position: "absolute", left: 0 }}
        >
          발송 대기열 페이지로 돌아가기
        </ButtonAntd>

        <Heading>발송 대상자 추가</Heading>

        <Button
          rightIcon={<LogoutOutlined />}
          onClick={() => {
            removeAccessToken();
            navigate("/");
          }}
          bg={"#333333"}
          color={"white"}
          size={"sm"}
          style={{ position: "absolute", right: 0 }}
        >
          로그아웃
        </Button>
      </Flex>

      <VStack as="form" onSubmit={handleSubmit(onSubmit)} position={"relative"}>
        <Grid
          templateColumns={{
            base: "repeat(1, 0fr)",
            md: "repeat(2, 0fr)",
            lg: "repeat(3, 0fr)",
            xl: "repeat(4, 0fr)",
          }}
          gap={formIds.length > 1 ? 6 : 0}
          h={"60vh"}
          overflow={"auto"}
          borderRadius={"md"}
          mb={5}
        >
          {formIds.map((id, index) => {
            return (
              <VStack
                key={id}
                p={3}
                borderWidth={2}
                borderRadius={"2xl"}
                height={"470px"}
              >
                <SubButton id={id} index={index} />
                {fields.map((field) => {
                  const fieldValue = watch(`${field.label}/${id}`);
                  const fieldError = errors[`${field.label}/${id}`];

                  return (
                    <FormControl
                      key={`${field.label}/${id}`}
                      isInvalid={!!errors[`${field.label}/${id}`]}
                      fontSize={12}
                      mb={3}
                    >
                      {field.label !== "dayOfWeek" ? (
                        <Tooltip
                          label={fieldValue}
                          placement="top-start"
                          isDisabled={
                            !["eventName", "room"].includes(field.label)
                          }
                        >
                          <Input
                            placeholder={field.placeholder}
                            {...register(`${field.label}/${id}`, {
                              ...field.options,
                            })}
                            h={7}
                            w={200}
                            fontSize={"inherit"}
                            boxShadow={"sm"}
                            type={field.type}
                            maxLength={field.maxLength}
                          />
                        </Tooltip>
                      ) : (
                        <Select
                          {...register(`${field.label}/${id}`, {
                            ...field.options,
                          })}
                          placeholder={field.placeholder}
                          h={"28px"}
                          w={200}
                          fontSize={"xs"}
                          boxShadow={"sm"}
                          color={"gray.500"}
                        >
                          {validDaysOfWeek.map((day) => {
                            const capitalizedDays =
                              day[0].toUpperCase() + day.slice(1);
                            return (
                              <option
                                value={capitalizedDays}
                                key={capitalizedDays}
                              >
                                {capitalizedDays}
                              </option>
                            );
                          })}
                        </Select>
                      )}

                      <FormHelperText
                        fontSize={"2xs"}
                        px={2}
                        color={"gray.400"}
                        mt={1}
                      >
                        {field.helperText}
                      </FormHelperText>
                    </FormControl>
                  );
                })}
              </VStack>
            );
          })}
        </Grid>

        <VStack>
          <AddButton isPending={isPending} />

          <Popover trigger="hover" placement="right">
            <PopoverTrigger>
              <Box>
                <Button
                  mt={"20px"}
                  w={"150px"}
                  bg={"#333333"}
                  color={"white"}
                  isLoading={isPending}
                  isDisabled={isDisabledUpdate}
                  type="submit"
                  _hover={{ bg: "gray" }}
                >
                  업로드
                </Button>
              </Box>
            </PopoverTrigger>

            {isDisabledUpdate && (
              <PopoverContent
                borderRadius={"lg"}
                bg={"black"}
                color={"white"}
                borderWidth={0}
                w={"100%"}
                maxW={"400px"}
                p={1}
              >
                <PopoverArrow shadow={"none"} bg={"black"} />
                <PopoverHeader
                  pb={0}
                  fontWeight="bold"
                  border="0"
                  fontSize={"xs"}
                  color="gray.200"
                >
                  업로드 불가능
                </PopoverHeader>
                <PopoverBody fontSize={"xs"} color="gray.100" pt={1}>
                  <UnorderedList>
                    <ListItem>
                      {`매주 ${dayOfWeekMap.get(DISABLED_TIME.dayOfWeek)}요일 ${
                        DISABLED_TIME.startTime
                      }~${DISABLED_TIME.endTime}시
                  사이에는 업로드가 불가능 합니다.`}
                    </ListItem>
                  </UnorderedList>
                </PopoverBody>
              </PopoverContent>
            )}
          </Popover>
        </VStack>
      </VStack>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseModal}
        isOpen={isOpenModal}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>발송 대기열 페이지로 이동하기</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            입력한 내용을 업로드하기 전 입니다. <br />
            지금 나가면 변경 내용이 저장되지 않습니다.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseModal}>
              취소
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                navigate("/queue");
              }}
            >
              나가기
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </VStack>
  );
};

export default Upload;

const AddButton = ({ isPending }: { isPending: boolean }) => {
  const addForm = useQueueStore((state) => state.addForm);

  return (
    <Tooltip
      label={"Form 추가"}
      modifiers={tooltipModifiers}
      placement="top"
      bg={"black"}
      color={"white"}
    >
      <IconButton
        aria-label="Add"
        icon={<AddIcon />}
        color={"gray"}
        isRound={true}
        onClick={addForm}
        h={8}
        w={8}
        isDisabled={isPending}
        borderWidth={2}
      />
    </Tooltip>
  );
};

const SubButton = ({ id, index }: { id: string; index: number }) => {
  const subForm = useQueueStore((state) => state.subForm);

  return (
    <IconButton
      aria-label="Add"
      icon={<MinusIcon fontSize={10} />}
      color={"red"}
      isRound={true}
      onClick={() => subForm(id)}
      size={"xs"}
      h={6}
      visibility={index === 0 ? "hidden" : undefined}
    />
  );
};
