import { DISABLED_TIME, dayOfWeekMap } from "./utils";

export const UXMessages = {
  변경_불가능:
    "매주 화요일 오전 10시 부터 오전 11시까지는 발송 대상자를 변경할 수 없습니다.",
  동시_시도:
    "현재 다른 사용자가 데이터를 업데이트 하는 중 입니다. 잠시 후 시도해주세요.",
  토큰_만료: "다시 로그인 해주세요.",
  범용적인_에러문구: (
    errorCode: number
  ) => `오류가 발생했습니다. 새로고침 및 재로그인 후 다시 시도해주세요. (E${errorCode})
  문제가 지속되는 경우, 관리자 (kimchangsic@sk.com)에게 연락해주세요.`,
};

export const WARN = [
  `매주 ${dayOfWeekMap.get(DISABLED_TIME.dayOfWeek)}요일 오전 ${
    DISABLED_TIME.endTime
  }시에 설문을 발송합니다.`,
  `매주 ${dayOfWeekMap.get(DISABLED_TIME.dayOfWeek)}요일 ${
    DISABLED_TIME.startTime < 12 ? "오전" : ""
  } ${DISABLED_TIME.startTime}시~${
    DISABLED_TIME.endTime
  }시 사이에는 설문 발송 목록을 업데이트 하거나, 삭제할 수 없습니다.`,
  `긴급한 사유로 ${dayOfWeekMap.get(DISABLED_TIME.dayOfWeek)}요일 오전 ${
    DISABLED_TIME.startTime
  }시~${
    DISABLED_TIME.endTime
  }시 사이에 설문 발송 목록을 삭제하거나, 수정해야 하는 경우, kimchangsic@sk.com에 요청해 주세요.`,
  "설문 발송 목록을 만들기 위해 데이터를 업로드 하실 때에는, 아래 <예시 파일 다운로드> 버튼을 누르셔서, 모양새를 맞춰 업로드 해주세요.",
  "엑셀 파일 열의 순서나 구성이 다른 경우 데이터를 업로드 할 수 없습니다.",
  "연락처가 중복되거나, 한국 휴대폰 번호가 아닌 경우 데이터를 업로드 할 수 없습니다.(설문은 알림톡을 통해 발송됩니다.)",
];
