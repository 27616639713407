import { useQuery } from "@tanstack/react-query";
import service from "../../service/service";

export const useQueueQuery = () => {
  const { data, isError, isRefetching, isFetching, error } = useQuery({
    queryFn: () => {
      return service.getQueue();
    },

    queryKey: ["queue"],
  });

  return {
    data,
    isError,
    isRefetching,
    isFetching,
    error,
  };
};
