import { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { dayOfWeekMap, DISABLED_TIME, parseExcel } from "../utils/utils";
import { useCreateQueueMutation } from "../hooks/mutations/useCreateQueueMutation";
import { useShallow } from "zustand/react/shallow";
import { useDisabledUpdateStore } from "../hooks/store/useDisabledUpdateStore";
import {
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import { UXMessages } from "../utils/constants";

const FileUploader = () => {
  const [loading, setLoading] = useState(false);

  const { isDisabledUpdate } = useDisabledUpdateStore(
    useShallow((state) => ({
      isDisabledUpdate: state.isDisabledUpdate,
      checkTimeAndDay: state.checkTimeAndDay,
    }))
  );

  const toast = useToast();

  const { createQueueMutation, isPending } = useCreateQueueMutation();

  const handleChange: UploadProps["onChange"] = async (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      try {
        const parsedData = await parseExcel(info.file.originFileObj as RcFile);

        if (isDisabledUpdate) {
          toast({
            title: "업로드 실패",
            description: UXMessages["변경_불가능"],
            status: "warning",
          });
        } else {
          createQueueMutation(parsedData);
        }
      } catch (error) {
        if (error instanceof Error) {
          toast({
            title: "업로드 실패",
            description: `엑셀 파일을 파싱하는 중 오류가 발생했습니다: ${error.message}`,
            status: "error",
          });
        } else {
          // 알 수 없는 타입의 에러 처리
          console.error("알 수 없는 오류:", error);
          toast({
            title: "알 수 없는 오류",
            description: UXMessages["범용적인_에러문구"](101),
            status: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const customRequest = ({ _, onSuccess }: any) => {
    // 파일을 서버에 업로드하지 않고 성공 콜백만 호출
    onSuccess();
  };

  const uploadButton = (
    <div>
      <Button
        icon={loading || isPending ? <LoadingOutlined /> : <PlusOutlined />}
        disabled={isPending || isDisabledUpdate}
      >
        엑셀 파일로 발송 대상자 업로드
      </Button>
    </div>
  );

  return (
    <Upload
      name="avatar"
      showUploadList={false}
      onChange={handleChange}
      customRequest={customRequest}
      accept=".xlsx,.xls"
    >
      <Popover trigger="hover" placement="left">
        <PopoverTrigger>{uploadButton}</PopoverTrigger>

        {isDisabledUpdate && (
          <PopoverContent
            borderRadius={"lg"}
            bg={"black"}
            color={"white"}
            borderWidth={0}
            w={"100%"}
            maxW={"400px"}
            p={1}
          >
            <PopoverArrow shadow={"none"} bg={"black"} />
            <PopoverHeader
              pb={0}
              fontWeight="bold"
              border="0"
              fontSize={"xs"}
              color="gray.200"
            >
              업로드 불가능
            </PopoverHeader>
            <PopoverBody fontSize={"xs"} color="gray.100" pt={1}>
              <UnorderedList>
                <ListItem>
                  {`매주 ${dayOfWeekMap.get(DISABLED_TIME.dayOfWeek)}요일 ${
                    DISABLED_TIME.startTime
                  }~${DISABLED_TIME.endTime}시
                  사이에는 업로드가 불가능 합니다.`}
                </ListItem>
              </UnorderedList>
            </PopoverBody>
          </PopoverContent>
        )}
      </Popover>
    </Upload>
  );
};

export default FileUploader;
