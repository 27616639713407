import { useMutation } from "@tanstack/react-query";
import service from "../../service/service";
import { ToastId, useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { AxiosError } from "axios";
import { removeAccessToken } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import { UXMessages } from "../../utils/constants";

export const useDeleteQueueMutation = () => {
  const navigate = useNavigate();

  const toast = useToast({ duration: 5000 });
  const toastIdRef = useRef<ToastId>();

  const { mutate: deleteQueueMutation, isPending } = useMutation({
    mutationFn: (_payload: { uuid: string; booking_arrival: string }[]) => {
      const payload = _payload.map((item) => ({
        uuid: item.uuid,
        date: item.booking_arrival,
      }));

      return service.deleteQueue(payload);
    },

    onMutate: () => {
      toastIdRef.current = toast({
        status: "loading",
        title: "삭제 중",
        duration: null,
      });
    },

    onSuccess: () => {
      const toastId = toastIdRef.current;

      toastId &&
        toast.update(toastId, {
          status: "success",
          title: "삭제 성공",
        });
    },

    onError: (error) => {
      const toastId = toastIdRef.current;

      if (error instanceof AxiosError && !!toastId) {
        if (error.response?.data === "INVALID TOKEN") {
          removeAccessToken();
          navigate("/");
          toast.update(toastId, {
            status: "error",
            title: "인증 실패",
            description: UXMessages["토큰_만료"],
          });
        } else if (error.response?.data === "LOCK UNAVAILABLE") {
          toast.update(toastId, {
            status: "error",
            title: "삭제 실패",
            description: UXMessages["동시_시도"],
          });
        } else if (error.response?.data === "EDIT NOT ALLOWED") {
          toast.update(toastId, {
            status: "error",
            title: "삭제 실패",
            description: UXMessages["변경_불가능"],
          });
        } else if ((error.response?.status ?? 501) >= 500) {
          toast.update(toastId, {
            status: "error",
            title: "삭제 실패",
            description: UXMessages["범용적인_에러문구"](301),
          });
        }
      } else {
        toastId &&
          toast.update(toastId, {
            status: "error",
            title: "삭제 실패",
            description: UXMessages["범용적인_에러문구"](302),
          });
      }
    },
  });

  return { deleteQueueMutation, isPending };
};
