import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  HStack,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
  useToast,
  ListItem,
  UnorderedList,
  Text,
  Image,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useQueueQuery } from "../hooks/queries/useQueueQuery";
import { useEffect, useRef, useState } from "react";
import { QueueData } from "../service/service";
import {
  dayOfWeekMap,
  DISABLED_TIME,
  intervalCheckTime,
  tooltipModifiers,
} from "../utils/utils";
import { useUpdateEffect } from "react-use";
import { useNavigate } from "react-router-dom";
import FileUploader from "../components/FileUploader";
import { Button as ButtonAntd } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDeleteQueueMutation } from "../hooks/mutations/useDeleteQueueMutation";
import { useDisabledUpdateStore } from "../hooks/store/useDisabledUpdateStore";
import { useShallow } from "zustand/react/shallow";
import { LogoutOutlined } from "@ant-design/icons";
import { removeAccessToken } from "../utils/auth";
import { UXMessages, WARN } from "../utils/constants";
import { EXCEL_IMAGE } from "../utils/images";
import { AxiosError } from "axios";
import { v4 as uuidv4 } from "uuid";

const Queue = () => {
  const navigate = useNavigate();

  const { isDisabledUpdate, checkTimeAndDay } = useDisabledUpdateStore(
    useShallow((state) => ({
      isDisabledUpdate: state.isDisabledUpdate,
      checkTimeAndDay: state.checkTimeAndDay,
    }))
  );
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [selectedRowsData, setSelectedRowsData] = useState<
    { uuid: string; booking_arrival: string }[]
  >([]);

  const { deleteQueueMutation, isPending } = useDeleteQueueMutation();
  const { data, isError, isRefetching, isFetching, error } = useQueueQuery();

  const columnHelper = createColumnHelper<QueueData>();
  const columns = [
    columnHelper.display({
      id: "selection",
      header: ({ table }) => (
        <Checkbox
          isChecked={table.getIsAllRowsSelected()}
          isIndeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
          colorScheme={"blackAlpha"}
          isDisabled={isFetching}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          isChecked={row.getIsSelected()}
          isIndeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          colorScheme={"blackAlpha"}
        />
      ),
      size: 15,
      minSize: 15,
      maxSize: 15,
    }),
    columnHelper.accessor("phone", {
      header: "전화번호",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("name", {
      header: "고객명",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("booking_arrival", {
      header: "예약날짜",
      cell: (info) => info.getValue(),
      size: 115,
    }),
    columnHelper.accessor("day_of_week", {
      header: "예약요일",
      cell: (info) => info.getValue(),
      size: 100,
    }),
    columnHelper.accessor("event_name", {
      header: "이벤트",
      cell: (info) => info.getValue(),
      size: 300,
    }),
    columnHelper.accessor("room", {
      header: "장소",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("onsite_contact", {
      header: "관리자",
      cell: (info) => info.getValue(),
      size: 100,
    }),
  ];
  const table = useReactTable({
    data: data ?? [],
    columns,
    state: {
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.uuid,
  });

  useUpdateEffect(() => {
    if (error instanceof AxiosError) {
      if (error.response?.data === "INVALID TOKEN") {
        removeAccessToken();
        navigate("/");
        toast({
          status: "error",
          title: "인증 실패",
          description: UXMessages["토큰_만료"],
        });
      } else if ((error.response?.status ?? 501) >= 500) {
        toast({
          status: "error",
          title: "발송 대기 목록 조회 실패",
          description: UXMessages["범용적인_에러문구"](101),
        });
      }
    } else if (error instanceof Error) {
      toast({
        status: "error",
        title: "발송 대기 목록 조회 실패",
        description: UXMessages["범용적인_에러문구"](102),
      });
    }
  }, [error]);

  useUpdateEffect(() => {
    const selectedData = table
      .getRowModel()
      .rows.filter((row) => rowSelection[row.id])
      .map((row) => {
        return {
          uuid: row.original.uuid,
          booking_arrival: row.original.booking_arrival,
        };
      });

    setSelectedRowsData(selectedData);
  }, [rowSelection]);

  useUpdateEffect(() => {
    if (isFetching) setRowSelection({});
  }, [isFetching]);

  useEffect(() => {
    const intervalId = setInterval(checkTimeAndDay, intervalCheckTime);

    return () => clearInterval(intervalId);
  }, [checkTimeAndDay]);

  return (
    <VStack spacing={5} pt={"20px"}>
      <Flex
        position={"relative"}
        w={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        mb={"10px"}
      >
        <Heading>발송 대기 목록</Heading>
        <Button
          rightIcon={<LogoutOutlined />}
          onClick={() => {
            removeAccessToken();
            navigate("/");
          }}
          style={{ position: "absolute", right: 0 }}
          bg={"#333333"}
          _hover={{ bg: "gray" }}
          color={"white"}
          size={"sm"}
        >
          로그아웃
        </Button>
      </Flex>

      <Box
        border={"2px solid #cacaca"}
        bg={"#f1f1f1"}
        w={"100%"}
        borderRadius={"15px"}
        py={4}
        px={6}
        mb={"5px"}
      >
        <Text fontSize={"large"} color={"#333333"} fontWeight={"bold"}>
          주의사항
        </Text>
        <UnorderedList>
          {WARN.map((item) => (
            <ListItem key={uuidv4()}>{item}</ListItem>
          ))}
        </UnorderedList>
      </Box>

      <HStack width={"100%"} justifyContent={"space-between"}>
        <Popover trigger="hover" offset={[100, 8]} placement="top">
          <PopoverTrigger>
            <Box>
              <Button
                bg={"#333333"}
                color={"white"}
                onClick={onOpen}
                isDisabled={
                  isRefetching ||
                  selectedRowsData.length === 0 ||
                  isPending ||
                  isDisabledUpdate
                }
                size={"sm"}
                _hover={{ bg: "gray" }}
              >
                선택한 발송 대상자 ({selectedRowsData.length}건) 삭제
              </Button>
            </Box>
          </PopoverTrigger>

          {isDisabledUpdate && (
            <PopoverContent
              borderRadius={"lg"}
              bg={"black"}
              color={"white"}
              borderWidth={0}
              w={"100%"}
              maxW={"400px"}
              p={1}
            >
              <PopoverArrow shadow={"none"} bg={"black"} />
              <PopoverHeader
                pb={0}
                fontWeight="bold"
                border="0"
                fontSize={"xs"}
                color="gray.200"
              >
                삭제 불가능
              </PopoverHeader>
              <PopoverBody fontSize={"xs"} color="gray.100" pt={1}>
                <UnorderedList>
                  <ListItem>
                    {`매주 ${dayOfWeekMap.get(DISABLED_TIME.dayOfWeek)}요일 ${
                      DISABLED_TIME.startTime
                    }~${DISABLED_TIME.endTime}시
                  사이에는 삭제가 불가능 합니다.`}
                  </ListItem>
                </UnorderedList>
              </PopoverBody>
            </PopoverContent>
          )}
        </Popover>

        <HStack>
          <ButtonAntd href="https://s3.ap-northeast-2.amazonaws.com/earlysloth.walkerhill.wedding.frontend/%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5_%E1%84%8B%E1%85%A6%E1%86%A8%E1%84%89%E1%85%A6%E1%86%AF_%E1%84%91%E1%85%A1%E1%84%8B%E1%85%B5%E1%86%AF.xlsx">
            <Flex direction={"row"} alignItems={"center"} gap={"3px"}>
              <Image
                src={EXCEL_IMAGE}
                w={"16px"}
                height={"16px"}
                flexShrink={0}
              />
              예시 엑셀 파일 다운로드
            </Flex>
          </ButtonAntd>

          <FileUploader />

          <ButtonAntd
            icon={<ArrowRightOutlined />}
            onClick={() => {
              navigate("/upload");
            }}
          >
            수기로 발송 대상자 추가
          </ButtonAntd>
        </HStack>
      </HStack>

      <Box height={"45vh"} overflow={"auto"} mb={30}>
        <Table
          variant={"striped"}
          size={"sm"}
          boxShadow={"0 0 0px 1px lightgray"}
          layout="fixed"
        >
          <Thead
            position={"sticky"}
            top={0}
            bg={"#333333"}
            color={"white"}
            zIndex={1}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    width={header.column.getSize()}
                    h={10}
                    color={"white"}
                    fontSize={"md"}
                  >
                    <Tooltip
                      label={
                        header.column.id !== "selection" ? header.column.id : ""
                      }
                      modifiers={tooltipModifiers}
                      placement="top-start"
                      bg={"black"}
                      color={"white"}
                    >
                      <Box isTruncated={header.column.id !== "selection"}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Box>
                    </Tooltip>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isFetching ? (
              <Tr>
                <Td
                  colSpan={
                    table.getHeaderGroups().flatMap((group) => group.headers)
                      .length
                  }
                >
                  <Center height="100px">
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      <Tooltip
                        label={String(cell.getValue() ?? "")}
                        modifiers={tooltipModifiers}
                        placement="top-start"
                        bg={"black"}
                        color={"white"}
                      >
                        <Box isTruncated={cell.column.id !== "selection"}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Box>
                      </Tooltip>
                    </Td>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td
                  colSpan={
                    table.getHeaderGroups().flatMap((group) => group.headers)
                      .length
                  }
                >
                  <Center height="100px" fontSize={"16px"}>
                    {isError
                      ? "일시적인 오류로 데이터를 불러오지 못했습니다. 로그아웃 후, 다시 로그인 해주세요."
                      : "발송 대기열이 비어 있습니다. 엑셀/수기 업로드를 통해 발송 대상자를 업로드 해주세요."}
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>삭제 확인</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {`${selectedRowsData.length}건의 발송 대상자를 삭제 하시겠습니까?`}
            <br />
            삭제한 데이터는 복구할 수 없습니다.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              취소
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                if (isDisabledUpdate) {
                  toast({
                    title: "삭제 실패",
                    description: UXMessages["변경_불가능"],
                    status: "warning",
                  });
                } else {
                  deleteQueueMutation(selectedRowsData);
                }
                onClose();
              }}
            >
              삭제
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </VStack>
  );
};

export default Queue;
