import { api } from "./api";
import { DynamicFormInputs } from "../pages/Upload";

export interface QueueData {
  uuid: string;
  phone: string;
  name: string;
  booking_arrival: string;
  day_of_week: string;
  event_name: string;
  room: string;
  onsite_contact: string;
}

export interface LogData {
  id: string;
  user_id: string;
  time: number;
  ip: string;
  action: string;
}

export interface CreateQueueResponse {
  ignored: number;
  tried: number;
  uploaded: number;
}

const service = {
  signIn: async (_payload: {
    id: string;
    password: string;
  }): Promise<{ user_id: string; token: string }> => {
    const payload = { user_id: _payload.id, password: _payload.password };

    const response = await api.post<{ token: string; user_id: string }>(
      "login",
      payload
    );
    return response.data;
  },

  createQueue: async (payload: DynamicFormInputs[]) => {
    const response = await api.post<CreateQueueResponse>("customers", payload);
    return response.data;
  },

  getQueue: async () => {
    const response = await api.get<QueueData[]>("customers");
    return response.data;
  },

  deleteQueue: async (payload: { date: string; uuid: string }[]) => {
    const response = await api.delete<{
      tried: 1;
      deleted: 0;
      failed: 1;
    }>("customers", { data: payload });
    return response;
  },
};

export default service;
