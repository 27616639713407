import { useNavigate } from "react-router-dom";
import service from "../../service/service";
import { useMutation } from "@tanstack/react-query";
import { setAccessToken } from "../../utils/auth";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import { UXMessages } from "../../utils/constants";

export const useSignInMutation = () => {
  const navigate = useNavigate();

  const toast = useToast({ duration: 5000 });

  const { mutate: signInMutation, isPending } = useMutation({
    mutationFn: ({ id, password }: { id: string; password: string }) => {
      return service.signIn({ id, password });
    },

    onSuccess: (data) => {
      setAccessToken(data.token);
      navigate("/queue");
    },

    onError: (error) => {
      if (error instanceof AxiosError) {
        if (error.response?.data === "LOGIN FAILED") {
          toast({
            status: "error",
            title: "로그인 실패",
            description:
              "로그인에 실패했습니다. 올바른 아이디와 패스워드가 맞는지 확인해주세요.",
          });
        } else if ((error.response?.status ?? 501) >= 500) {
          toast({
            status: "error",
            title: "로그인 실패",
            description: UXMessages["범용적인_에러문구"](101),
          });
        }
      } else {
        toast({
          status: "error",
          title: "로그인 실패",
          description: UXMessages["범용적인_에러문구"](102),
        });
      }
    },
  });

  return { signInMutation, isPending };
};
