import axios from "axios";
import { getAccessToken } from "../utils/auth";

export const api = axios.create({
  baseURL:
    "https://frvcmbsg2cxga4uc64lwuuhc3u0ixxuz.lambda-url.ap-northeast-2.on.aws",
});

api.interceptors.request.use((config) => {
  const token = getAccessToken();
  if (token) {
    config.headers.Authorization = `${token}`;
  }

  return config;
});
